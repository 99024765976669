import React, { useEffect, useState } from 'react'
import {
  makeStyles,
  createStyles,
  Theme,
  Typography,
  Grid,
  Fab,
} from '@material-ui/core'
import QuantitySelector from './QuantitySelector'
import { connect } from 'react-redux'
import { Variant, Cart, GAEvent } from '../../typings/base'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import { useMatomo } from '@datapunt/matomo-tracker-react'
import clsx from 'clsx'
import {
  checkStockQuantity,
  numberToPrice,
  thumbnailRatio,
} from '../../utils/utils'
import { addToCartUtility } from '../../utils/addToCart'
import CMSText from '../Global/CMSText'
import { useRouter } from 'next/router'
import Price from './Price'
import ImgKitHandler from '../ImgKitHandler/ImgKitHandler'
import { useCart } from '../../hooks/useCart'
import { useTakeback } from '../../hooks/useTakeback'
import { useTranslation } from 'react-i18next'
import { PrismicBasket } from '../../interfaces/common/prismicBasket'

const IMG_WIDTH = 95

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: theme.palette.common.white,
      borderRadius: theme.shape.borderRadius,
      boxShadow: theme.shadows[1],
      margin: theme.spacing(1, 0),
      padding: theme.spacing(1),
    },
    notAvailable: {
      background: theme.extraColors.greyLight,
      border: `2px solid ${theme.palette.error.main}`,
    },
    columnMedia: {
      flex: `0 0 ${IMG_WIDTH}px`,
    },
    columnContent: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    isSize: {
      [theme.breakpoints.down('sm')]: {
        display: 'block',
      },
      '&>img': {
        [theme.breakpoints.up('sm')]: {
          width: 164,
          height: 164,
        },
      },
    },
    image: {
      display: 'block',
      width: 70,
      height: 70,
      objectFit: 'contain',
      overflow: 'hidden',
      borderRadius: theme.shape.borderRadius,
      '[data-ratio] &': {
        objectFit: 'contain',
      },
    },
    details: {
      flexGrow: 1,
      padding: theme.spacing(0, 0, 0, 0.5),
      '& > * + *': {
        marginTop: theme.spacing(1),
      },
    },
    detailsHead: {
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    row: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      '& > * + *': {
        marginLeft: theme.spacing(1),
      },
    },
    productName: {
      textTransform: 'capitalize',
    },
    dailyPrice: {
      color: theme.palette.common.black,
      fontWeight: 'normal',
      padding: theme.spacing(0, 1),
    },
    availability: {
      margin: 0,
      color: theme.palette.error.main,
      '&.enought': {
        color: theme.palette.success.main,
      },
    },
    sizes: {
      marginTop: theme.spacing(1),
    },
    sizesList: {
      border: `1px solid ${theme.extraColors.grey}`,
      padding: theme.spacing(0.5),
      borderRadius: theme.shape.borderRadius,
      '&>.MuiButton-root': {
        padding: theme.spacing(1, 2),
        margin: theme.spacing(0.5),
        backgroundColor: theme.extraColors.greyLight,
        '&:hover, &:focus': {
          backgroundColor: theme.extraColors.grey,
        },
        '&.selected': {
          color: theme.palette.common.white,
          backgroundColor: theme.palette.primary.main,
          '&:hover, &:focus': {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main,
          },
        },
        '&.sold-out': {
          opacity: 0.5,
          color: theme.palette.error.main,
        },
      },
    },
    isNotGender: {
      '&>div>div>.MuiButton-root': {
        padding: theme.spacing(1),
        minWidth: 34,
        width: 'auto',
        height: 34,
        lineHeight: '34px',
      },
    },
    basketSizes: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    basketSizesList: {
      maxWidth: 75,
      lineHeight: 1,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    basketSizesLabel: {
      display: 'block',
      width: '100%',
      fontSize: 12,
      color: theme.extraColors.greyText,
    },
    basketSizesItem: {
      lineHeight: 1,
      '&+span': {
        marginLeft: theme.spacing(1),
      },
    },
    nameProduct: {
      minHeight: 31,
      '&> span': {
        color: theme.palette.secondary.main,
        fontWeight: 400,
      },
    },
    quantitySelector: {},
    smallText: {
      fontSize: 11,
      lineHeight: 1.1,
    },
    smallTextGrey: {
      fontSize: 11,
      color: theme.extraColors.grey,
      display: 'inline-block',
    },
    smallMarginTop: {},
    delete: {
      width: 20,
      height: 20,
      minHeight: 20,
      padding: 0,
      boxShadow: 'none',
      background: 'none',
      '&:hover, &:focus': {
        color: theme.palette.error.main,
        background: 'none',
      },
      '& .MuiSvgIcon-root': {
        height: '.75em',
        width: '.75em',
      },
    },
    total: {},
    totalTryOut: {
      marginTop: theme.spacing(1.5),
    },
    valign: {
      display: 'flex',
      alignItems: 'center',
      '& .MuiGrid-container': {
        alignItems: 'center',
      },
    },
  })
)

type Tprops = {
  id: number
  name: string
  photoThumb: string
  isTryOut: boolean
  stock: any
  priceRental?: number
  basket: PrismicBasket
  parameters: any
  isAvailable?: boolean
  dayRange: number
  productCode: string
  availability?: number
  buyAble: boolean
  variant: Variant
  cart: Cart
  isSki: boolean
  locked?: boolean
  loading: boolean
  hidePrice?: boolean
  showSizes?: boolean
  settings: any
  isSecondHand?: boolean
  unit?: any
  takeback?: any
}

const BasketProductRow = ({
  id,
  name,
  stock,
  photoThumb,
  priceRental,
  basket,
  availability,
  parameters,
  isAvailable,
  buyAble,
  cart,
  variant,
  dayRange,
  isSki,
  isTryOut,
  locked,
  loading,
  hidePrice,
  showSizes,
  settings,
  isSecondHand = false,
  unit,
  takeback,
}: Tprops) => {
  const { changeProductQuantityInCart, removeProductFromCart } = useCart()
  const classes = useStyles()
  const { trackEvent, pushInstruction } = useMatomo()
  const router = useRouter()
  const { productCheckState } = useTakeback()

  const [variantPrice, setVariantPrice] = useState(0)
  const displayFlexPrice = settings?.display_flex_price_a_day
  const thumbRatio = thumbnailRatio(settings?.thumbnail_ratio)
  const states = productCheckState(takeback?.data)
  const disableDelete = basket?.data?.disable_product_removal
  const disableQtySelector = basket?.data?.disable_quantity_change

  useEffect(() => {
    !buyAble
      ? setVariantPrice(
          isTryOut ? variant.tryingOnPrice : variant.price.current
        )
      : setVariantPrice(variant.purchasePrice)
  }, [])
  const isSubscription =
    process.env.STORE_TYPE === 'subscription' ||
    process.env.STORE_TYPE === 'classic_subscription'
  const isClassicSub = process.env.STORE_TYPE === 'classic_subscription'
  const { t } = useTranslation()

  const hidePriceInCart = basket?.data?.hide_price_per_day_in_cart
  // const dispatch = useDispatch();

  const quantity =
    cart &&
    cart?.item?.items?.length > 0 &&
    typeof cart.item.items.find((el) => el.id === id) !== 'undefined'
      ? cart.item.items.find((el) => el.id === id).quantity
      : 0

  const productInCart =
    cart &&
    cart.item &&
    cart.item.items.length > 0 &&
    typeof cart.item.items.find(
      (el) => el.product.variants[0].code === variant.code
    ) !== 'undefined' &&
    cart.item.items.find((el) => el.product.variants[0].code === variant.code)

  const onQuantityChange = async (quantity: number) => {
    let inStock = 0
    if (productInCart) {
      inStock = checkStockQuantity(
        stock,
        productInCart.product.variants[0],
        'basket'
      )
    }
    const variantKey = Object.keys(productInCart.product.variants)[0]
    const response = addToCartUtility(
      variantKey !== '0' ? variantKey : productInCart.product.variants[0].code,
      quantity,
      cart,
      parameters,
      productInCart,
      false,
      inStock
    )

    trackEvent({
      category: 'Basket',
      action: `Quantity changed for product in basket`,
    })

    if (availability) {
      const unitaryPrice = numberToPrice(
        productInCart?.product?.variants[0]?.price?.current
      )
      const totalPrice = numberToPrice(unitaryPrice * quantity)

      switch (response.message) {
        case 'limit':
        case 'max':
        case 'add':
          trackEvent({
            category: 'Add to Cart',
            action: `${productInCart?.product?.name}`,
            name: `${router.pathname}`,
            value: totalPrice,
          })
          if (response.productInCart) {
            changeProductQuantityInCart(
              productInCart.id,
              { quantity },
              GAEvent.ADD_TO_CART,
              inStock
            )

            pushInstruction(
              'addEcommerceItem',
              productInCart?.product?.variants[0]?.sku,
              productInCart?.product?.name,
              productInCart?.product?.taxons?.main,
              unitaryPrice,
              quantity
            )
            pushInstruction(
              'trackEcommerceCartUpdate',
              numberToPrice(cart?.item?.totals?.total)
            )
          }
          break
        case 'remove':
          trackEvent({
            category: 'Remove from Cart',
            action: `${productInCart?.product?.name}`,
            name: `${router.pathname}`,
            value: totalPrice,
          })
          changeProductQuantityInCart(
            productInCart.id,
            { quantity },
            GAEvent.REMOVE_FROM_CART,
            inStock
          )
          pushInstruction(
            'addEcommerceItem',
            productInCart?.product?.variants[0]?.sku,
            productInCart?.product?.name,
            productInCart?.product?.taxons?.main,
            unitaryPrice,
            quantity
          )
          pushInstruction(
            'trackEcommerceCartUpdate',
            numberToPrice(cart.item.totals.total)
          )
          break
        case 'delete':
          trackEvent({
            category: 'Delete from Cart',
            action: `${productInCart?.product?.name}`,
            name: `${router.pathname}`,
            value: totalPrice,
          })
          removeProductFromCart(productInCart)
          pushInstruction(
            'removeEcommerceItem',
            productInCart?.product?.variants[0]?.sku
          )
          pushInstruction(
            'trackEcommerceCartUpdate',
            numberToPrice(cart.item.totals.total)
          )
          break
        default:
          break
      }
    }
  }

  function deleteItem() {
    const data = isSecondHand
      ? {
          puid: unit.puid,
          condition: unit.productCondition,
        }
      : undefined

    removeProductFromCart(
      productInCart,
      data,
      isSecondHand ? 1 : productInCart.quantity
    )
  }

  return (
    <>
      <article
        className={clsx(
          'cart-item',
          classes.root,
          isAvailable && classes.notAvailable
        )}
      >
        <Grid container spacing={1} wrap="nowrap">
          <Grid item className={clsx('cart-item__media', classes.columnMedia)}>
            <div data-ratio={'ar-1-1'}>
              <ImgKitHandler
                classes={classes.image}
                alt={name}
                imagePaths={photoThumb}
                settings={{
                  quality: 75,
                  width: settings?.small_image_size,
                  ar: thumbRatio?.substring(3),
                  defaultImage: settings?.default_image,
                }}
              />
            </div>
          </Grid>
          <Grid
            item
            className={clsx(
              'cart-item__content',
              classes.columnContent,
              hidePrice && classes.valign,
              disableQtySelector && classes.valign
            )}
          >
            <Grid container spacing={1} wrap="nowrap" justify="space-between">
              <Grid item xs="auto">
                <Typography
                  variant="h6"
                  component="div"
                  className={clsx(
                    'basket__title',
                    !disableQtySelector && classes.nameProduct
                  )}
                >
                  {name}
                  {isSecondHand && (
                    <>
                      &nbsp; - &nbsp; {variant.size}
                      &nbsp; - &nbsp;{' '}
                      {states[`${unit?.productCondition}`].title}
                    </>
                  )}
                  {!isSecondHand && showSizes && variant.name !== 'undefined' && (
                    <span className={'basket__variant'}>
                      {variant?.size && <>&nbsp; - &nbsp; {variant?.size}</>}
                      {isTryOut && (
                        <>
                          &nbsp; - &nbsp;
                          <CMSText
                            asText
                            data={basket?.data?.try_out}
                            defaultText={t('texts:basket:try_out')}
                          />
                        </>
                      )}
                    </span>
                  )}
                </Typography>
              </Grid>
              {!disableDelete && (
                <Grid item xs="auto">
                  <Fab
                    size="small"
                    className={classes.delete}
                    onClick={deleteItem}
                  >
                    <DeleteForeverIcon />
                  </Fab>
                </Grid>
              )}
            </Grid>
            {isSki && (
              <Grid
                container
                spacing={1}
                wrap="nowrap"
                alignItems="center"
                justify="space-between"
              >
                <Grid item xs="auto">
                  <Typography className={`basket__time`}>
                    {quantity > 1 ? quantity + ' paires' : quantity + ' paire'}
                  </Typography>
                </Grid>
                <Grid item xs="auto">
                  {priceRental && !hidePriceInCart && (
                    <Typography
                      className={`basket__time ${classes.smallText}`}
                      color={'secondary'}
                    >
                      <Price
                        isShort
                        showDayOrMonth
                        amount={variantPrice * quantity}
                        dayOrMonth={settings?.day_or_month}
                        dayOrMonthShort={settings?.day_or_month_short}
                        buyAble={buyAble}
                        displayFlexPrice={displayFlexPrice}
                      />
                      <span className={classes.smallTextGrey}>
                        <CMSText
                          asText
                          data={basket?.data?.for_short}
                          defaultText={t('texts:basket:for_short')}
                        />{' '}
                        {dayRange}
                        <CMSText
                          asText
                          data={
                            dayRange <= 1
                              ? basket?.data?.day_singular
                              : basket?.data?.day_plural
                          }
                          defaultText={
                            dayRange <= 1
                              ? t('texts:general:day_singular')
                              : t('texts:general:days_plural')
                          }
                        />
                      </span>
                    </Typography>
                  )}
                </Grid>
              </Grid>
            )}
            {!hidePrice && !disableQtySelector ? (
              <>
                <Grid
                  container
                  spacing={1}
                  wrap="nowrap"
                  alignItems="center"
                  justify="space-between"
                >
                  <Grid item xs="auto">
                    {locked && !isSecondHand && (
                      <div className={classes.quantitySelector}>
                        <QuantitySelector
                          value={quantity}
                          align={'left'}
                          handleChange={onQuantityChange}
                          min={0}
                          max={variant?.maxStock ? variant?.maxStock : null}
                          small
                          loading={loading}
                          variantSku={variant.sku}
                        />
                      </div>
                    )}
                  </Grid>
                  <Grid item xs="auto">
                    {!isClassicSub && !isSubscription && !isTryOut && (
                      <>
                        {isSecondHand && (
                          <Typography
                            className={`basket__time ${classes.smallText}`}
                            color={'secondary'}
                          >
                            <Price showDayOrMonth={false} amount={unit.price} />
                          </Typography>
                        )}
                        {priceRental && !hidePriceInCart && (
                          <Typography
                            className={`basket__time ${classes.smallText}`}
                            color={'secondary'}
                          >
                            {!isSubscription ? (
                              <>
                                <Price
                                  isShort
                                  showDayOrMonth
                                  amount={variantPrice * quantity}
                                  dayOrMonth={settings?.day_or_month}
                                  dayOrMonthShort={settings?.day_or_month_short}
                                  buyAble={buyAble}
                                  displayFlexPrice={displayFlexPrice}
                                />
                              </>
                            ) : (
                              <>
                                <Price
                                  isShort
                                  showDayOrMonth
                                  amount={variantPrice * quantity}
                                  dayOrMonth={settings?.day_or_month}
                                  dayOrMonthShort={settings?.day_or_month_short}
                                  buyAble={buyAble}
                                  displayFlexPrice={displayFlexPrice}
                                />
                              </>
                            )}{' '}
                            {!isSubscription && !buyAble && (
                              <span className={classes.smallTextGrey}>
                                <CMSText
                                  asText
                                  data={basket?.data?.for_short}
                                  defaultText={t('texts:basket:for_short')}
                                />{' '}
                                {dayRange}
                                <CMSText
                                  asText
                                  data={
                                    dayRange <= 1
                                      ? basket?.data?.day_singular
                                      : basket?.data?.day_plural
                                  }
                                  defaultText={
                                    dayRange <= 1
                                      ? t('texts:general:day_singular')
                                      : t('texts:general:days_plural')
                                  }
                                />
                              </span>
                            )}
                          </Typography>
                        )}
                      </>
                    )}
                    {!isClassicSub && !isTryOut && !buyAble && (
                      <Typography
                        variant={'h5'}
                        component={'div'}
                        color={'secondary'}
                        align="right"
                        className={clsx('basket-product__total', classes.total)}
                      >
                        <div>
                          <Price
                            amount={
                              !isSubscription
                                ? variantPrice * quantity * dayRange
                                : variant.price.current * quantity
                            }
                            showDayOrMonth={false}
                            isShort
                            dayOrMonth={settings?.day_or_month}
                            dayOrMonthShort={settings?.day_or_month_short}
                            displayFlexPrice={displayFlexPrice}
                          />
                        </div>
                      </Typography>
                    )}
                    {isTryOut && (
                      <Typography
                        variant={'h5'}
                        component={'div'}
                        color={'secondary'}
                        align="right"
                        className={clsx(
                          'basket-product__total',
                          classes.total,
                          classes.totalTryOut
                        )}
                      >
                        <Price
                          amount={
                            !isSubscription
                              ? variantPrice * quantity
                              : variant.price.current
                          }
                          isShort
                          showDayOrMonth={false}
                          dayOrMonth={settings?.day_or_month}
                          dayOrMonthShort={settings?.day_or_month_short}
                          displayFlexPrice={displayFlexPrice}
                        />
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </>
            ) : (
              <>
                <Grid
                  container
                  spacing={1}
                  wrap="nowrap"
                  alignItems="center"
                  justify="space-between"
                >
                  <Grid item xs="auto">
                    {quantity > 1
                      ? quantity + ' ' + t('texts:basket:products')
                      : quantity + ' ' + t('texts:basket:product')}
                  </Grid>
                  <Grid item xs="auto">
                    {!isClassicSub && !isSubscription && !isTryOut && (
                      <>
                        {isSecondHand && (
                          <Typography
                            className={`basket__time ${classes.smallText}`}
                            color={'secondary'}
                          >
                            <Price showDayOrMonth={false} amount={unit.price} />
                          </Typography>
                        )}
                        {priceRental && !hidePriceInCart && (
                          <Typography
                            className={`basket__time ${classes.smallText}`}
                            color={'secondary'}
                          >
                            {!isSubscription ? (
                              <>
                                <Price
                                  isShort
                                  showDayOrMonth
                                  amount={variantPrice * quantity}
                                  dayOrMonth={settings?.day_or_month}
                                  dayOrMonthShort={settings?.day_or_month_short}
                                  buyAble={buyAble}
                                  displayFlexPrice={displayFlexPrice}
                                />
                              </>
                            ) : (
                              <>
                                <Price
                                  isShort
                                  showDayOrMonth
                                  amount={variantPrice * quantity}
                                  dayOrMonth={settings?.day_or_month}
                                  dayOrMonthShort={settings?.day_or_month_short}
                                  buyAble={buyAble}
                                  displayFlexPrice={displayFlexPrice}
                                />
                              </>
                            )}{' '}
                            {!isSubscription && !buyAble && (
                              <span className={classes.smallTextGrey}>
                                <CMSText
                                  asText
                                  data={basket?.data?.for_short}
                                  defaultText={t('texts:basket:for_short')}
                                />{' '}
                                {dayRange}
                                <CMSText
                                  asText
                                  data={
                                    dayRange <= 1
                                      ? basket?.data?.day_singular
                                      : basket?.data?.day_plural
                                  }
                                  defaultText={
                                    dayRange <= 1
                                      ? t('texts:general:day_singular')
                                      : t('texts:general:days_plural')
                                  }
                                />
                              </span>
                            )}
                          </Typography>
                        )}
                      </>
                    )}
                    {!isClassicSub && !isTryOut && !buyAble && (
                      <Typography
                        variant={'h5'}
                        component={'div'}
                        color={'secondary'}
                        align="right"
                        className={clsx('basket-product__total', classes.total)}
                      >
                        <div>
                          <Price
                            amount={
                              !isSubscription
                                ? variantPrice * quantity * dayRange
                                : variant.price.current * quantity
                            }
                            showDayOrMonth={false}
                            isShort
                            dayOrMonth={settings?.day_or_month}
                            dayOrMonthShort={settings?.day_or_month_short}
                            displayFlexPrice={displayFlexPrice}
                          />
                        </div>
                      </Typography>
                    )}
                    {isTryOut && (
                      <Typography
                        variant={'h5'}
                        component={'div'}
                        color={'secondary'}
                        align="right"
                        className={clsx(
                          'basket-product__total',
                          classes.total,
                          classes.totalTryOut
                        )}
                      >
                        <Price
                          amount={
                            !isSubscription
                              ? variantPrice * quantity
                              : variant.price.current
                          }
                          isShort
                          showDayOrMonth={false}
                          dayOrMonth={settings?.day_or_month}
                          dayOrMonthShort={settings?.day_or_month_short}
                          displayFlexPrice={displayFlexPrice}
                        />
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </article>
    </>
  )
}
const mapStateToProps = (state) => ({
  cart: state.cart,
  parameters: state.parameters,
})

export default connect(mapStateToProps)(BasketProductRow)
