import React, { useCallback } from 'react'
import Image from 'next/image'
import Skeleton from '@material-ui/lab/Skeleton'

type imgSettings = {
  height?: number
  width?: number
  quality?: number
  ar?: string
  defaultImage?: any
}

type Tprops = {
  imagePaths: any
  classes?: any
  alt?: string
  onClickEvent?: any
  settings?: imgSettings
}

const ImgKitHandler = ({
  classes,
  imagePaths,
  alt,
  onClickEvent,
  settings,
}: Tprops) => {
  let imageHeight
  switch (settings.ar) {
    case '3-4':
      imageHeight = Math.round(settings.width * (4 / 3))
      break
    case '4-3':
      imageHeight = Math.round(settings.width * (3 / 4))
      break
    case '16-9':
      imageHeight = Math.round(settings.width * (16 / 9))
      break
    default:
      imageHeight = settings.width
      break
  }

  const imageKitLoader = useCallback(() => {
    const params = [`w-${settings.width}`, `h-${imageHeight}`]
    if (settings.ar) {
      params.push(`ar-${settings.ar}`)
    }
    if (settings.quality) {
      params.push(`q-${settings.quality}`)
    }
    const paramsString = params.join(',')
    let urlEndpoint = process.env.IMGKIT_URL
    if (urlEndpoint[urlEndpoint.length - 1] === '/')
      urlEndpoint = urlEndpoint.substring(0, urlEndpoint.length - 1)

    // Interval variable for cache-busting version parameter (`v`),
    // updated every 4 hours to ensure periodic cache refresh.
    const fourHourInterval = Math.floor(Date.now() / (4 * 60 * 60 * 1000))

    return `${urlEndpoint}/${imagePaths.path}?tr=${paramsString}&v=${fourHourInterval}`
  }, [imagePaths, settings])

  return (
    <>
      {typeof imagePaths === 'undefined' ||
      typeof imagePaths.path === undefined ? (
        settings?.defaultImage && settings?.defaultImage?.url ? (
          <Image
            src={settings?.defaultImage?.url}
            className={classes}
            alt={alt}
            layout="fill"
            loading="lazy"
          />
        ) : (
          <Skeleton variant="rect" animation={false} />
        )
      ) : process.env.USE_IMG_KIT === 'true' ? (
        <Image
          loader={imageKitLoader}
          src={imagePaths?.path ? imagePaths?.path : '/images/placeholder.jpg'}
          className={classes}
          alt={alt}
          onClick={onClickEvent}
          layout="fill"
          loading="lazy"
        />
      ) : (
        <Image
          src={'/images/placeholder.jpg'}
          className={classes}
          alt={alt}
          onClick={onClickEvent}
          layout="fill"
          loading="lazy"
        />
      )}
    </>
  )
}

export default ImgKitHandler
