import React, { useEffect, useMemo, useState } from 'react'
import clsx from 'clsx'
import {
  makeStyles,
  createStyles,
  Theme,
  Typography,
  Collapse,
  Tooltip,
} from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'
import { Cart } from '../../typings/base'
import { useRouter } from 'next/router'
import CMSText from '../Global/CMSText'
import Price from './Price'
import { calculTotalWithBuyAbleProducts } from '../../utils/utils'
import BasketShowDetails from './BasketShowDetails'
import { ParametersReducerState } from '../../redux/reducers/parametersReducer'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapperShipping: {
      margin: 0,
      padding: theme.spacing(1),
    },
    row: {
      display: 'flex',
      padding: theme.spacing(1, 0.5),
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    rowBorder: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: theme.spacing(1, 0.5),
      borderTop: `1px solid ${theme.extraColors.greyLight}`,
    },
    giftCardExplanation: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: 10,
      fontWeight: 500,
      padding: theme.spacing(0.4),
      borderRadius: theme.shape.borderRadius,
      color: 'white',
      backgroundColor: theme.palette.primary.main,
    },
    giftCardIcon: {
      fontSize: 14,
      marginRight: theme.spacing(0.4),
    },
    rowNoBorder: {
      border: 0,
    },
    rowHeavyBorder: {
      borderTopColor: theme.extraColors.grey,
    },
    rowHeavyBorderBottom: {
      borderTop: 0,
      borderBottom: `1px solid ${theme.extraColors.grey}`,
    },
    shipping: {
      fontSize: '80%',
      fontWeight: 400,
      color: `${theme.extraColors.greyText}`,
    },
    left: {
      display: 'flex',
      flexDirection: 'row',
    },
    green: {
      color: theme.palette.success.main,
    },
    deposit: {
      display: 'flex',
      alignItems: 'center',
    },
  })
)

type Tprops = {
  cart: Cart
  t: any
  basket: any
  dayRange: number
  isBuyAbleProducts?: boolean
  showDetailsOnBuyAbleProducts?: boolean
  settings?: any
  mixedBasket?: boolean
  isSecondHand?: boolean
  isFunnel?: boolean
  user: any
  parameters: ParametersReducerState
}

const BasketTotalsDad = ({
  cart,
  basket,
  dayRange,
  mixedBasket,
  isSecondHand,
  t,
  showDetailsOnBuyAbleProducts,
  isBuyAbleProducts,
  settings,
  user,
  parameters,
}: Tprops) => {
  const classes = useStyles()
  const router = useRouter()
  const [totalWithoutBuyAble, setTotalWithoutBuyAble] = useState(0)
  const [showDetails, setShowDetails] = useState(true)
  const isEndFunnel =
    router.route === '/shipping' || router.route === '/payment'
  const amountTotal = useMemo(() => {
    return isEndFunnel
      ? totalWithoutBuyAble + cart?.item?.totals?.shipping
      : totalWithoutBuyAble
  }, [isEndFunnel, totalWithoutBuyAble, cart?.item?.totals?.shipping])

  const notDisplayWithoutShipping = basket?.data?.not_display_without_shipping
  const deposit = useMemo(() => {
    return +parameters.items.deposit || 0
  }, [parameters.items.deposit])

  const depositAmount = useMemo(
    () =>
      cart.item?.items.reduce((acc, item) => {
        const originalPrice =
          item.product.variants[0]?.originalPrice?.current ?? 0
        if (originalPrice) {
          return acc + originalPrice * item.quantity * deposit
        }
        return acc
      }, 0),
    [cart.item?.items]
  )

  useEffect(() => {
    // calcul total without buyAbleProducts
    const total = calculTotalWithBuyAbleProducts(cart, isBuyAbleProducts)
    setTotalWithoutBuyAble(total)
  }, [cart])

  return (
    <>
      <div className={clsx('shipping-infos__wrapper', classes.wrapperShipping)}>
        {!isBuyAbleProducts && showDetailsOnBuyAbleProducts && (
          <>
            <BasketShowDetails
              t={t}
              basket={basket}
              setShowDetails={setShowDetails}
              showDetails={showDetails}
            />

            <Collapse in={showDetails} timeout="auto" unmountOnExit>
              <div>
                {/* Price total per day */}
                {!isBuyAbleProducts &&
                  basket?.data?.enable_total_price_per_day && (
                    <div className={classes.row}>
                      <Typography variant={'body2'}>
                        <CMSText
                          asText
                          data={basket?.data?.kit_price}
                          defaultText={t('texts:basket:kit_price')}
                        />
                      </Typography>
                      <div className={classes.left}>
                        <Typography variant={'body2'}>
                          <b>
                            <Price
                              amount={totalWithoutBuyAble / dayRange}
                              dayOrMonth={settings?.day_or_month}
                              dayOrMonthShort={settings?.day_or_month_short}
                              showDayOrMonth
                              isShort
                            />
                          </b>
                        </Typography>
                      </div>
                    </div>
                  )}
                {/* Total price without shipping */}
                {basket?.data?.enable_total_without_shipping && (
                  <div className={classes.row}>
                    <Typography variant={'body2'}>
                      <CMSText
                        asText
                        data={basket?.data?.total_without_shipping}
                        defaultText={t('texts:basket:total_without_shipping')}
                      />
                    </Typography>
                    <div className={classes.left}>
                      <Typography variant={'body2'}>
                        <b>
                          <Price
                            amount={totalWithoutBuyAble}
                            showDayOrMonth={false}
                            dayOrMonth={settings?.day_or_month}
                            dayOrMonthShort={settings?.day_or_month_short}
                          />
                        </b>
                      </Typography>
                    </div>
                  </div>
                )}
                {/* Total Weight */}
                {basket?.data?.enable_total_weight && (
                  <div className={classes.row}>
                    <Typography variant={'body2'}>
                      <CMSText
                        asText
                        data={basket?.data?.total_weight}
                        defaultText={t('texts:basket:total_weight')}
                      />
                    </Typography>
                    <div className={classes.left}>
                      <Typography variant={'body2'}>
                        <b>{cart.item.productsTotalWeight} kg</b>
                      </Typography>
                    </div>
                  </div>
                )}
                {/* Durée */}
                {!isBuyAbleProducts && basket?.data?.enable_duration && (
                  <div className={classes.rowBorder}>
                    <Typography variant={'body2'}>
                      <CMSText
                        asText
                        data={basket?.data?.duration}
                        defaultText={t('texts:basket:duration')}
                      />
                      &nbsp;
                    </Typography>
                    <div className={classes.left}>
                      <Typography variant={'body2'}>
                        <b>
                          {dayRange}{' '}
                          <CMSText
                            asText
                            data={
                              dayRange <= 1
                                ? basket?.data?.day_singular
                                : basket?.data?.day_plural
                            }
                            defaultText={
                              dayRange <= 1
                                ? t('texts:general:day_singular')
                                : t('texts:general:days_plural')
                            }
                          />
                        </b>
                      </Typography>
                    </div>
                  </div>
                )}
                {/* Assurance */}
                {basket?.data?.enable_assurance && (
                  <div className={classes.rowBorder}>
                    <Typography variant={'body2'}>
                      <CMSText
                        asText
                        data={basket?.data?.assurance}
                        defaultText={t('texts:basket:assurance')}
                      />
                    </Typography>
                    <div className={classes.left}>
                      <Typography variant={'body2'}>
                        <b>
                          <CMSText
                            asText
                            data={basket?.data?.free}
                            defaultText={t('texts:basket:free')}
                          />
                        </b>
                      </Typography>
                    </div>
                  </div>
                )}
                {basket?.data?.enable_shipping && (
                  <>
                    {isEndFunnel && !isSecondHand && (
                      <div className={classes.rowBorder}>
                        <Typography variant={'body2'}>
                          <CMSText
                            asText
                            data={basket?.data?.shipping}
                            defaultText={t('texts:basket:shipping')}
                          />
                        </Typography>
                        <div className={classes.left}>
                          <Typography variant={'body2'}>
                            <b>
                              {cart.item.totals.shipping === 0 ? (
                                <CMSText
                                  asText
                                  data={basket?.data?.free}
                                  defaultText={t('texts:basket:free')}
                                />
                              ) : (
                                <>
                                  <Price
                                    amount={cart.item.totals.shipping}
                                    showDayOrMonth={false}
                                    dayOrMonth={settings?.day_or_month}
                                    dayOrMonthShort={
                                      settings?.day_or_month_short
                                    }
                                  />
                                </>
                              )}
                            </b>
                          </Typography>
                        </div>
                      </div>
                    )}
                  </>
                )}
                {/* Promotion */}
                {basket?.data?.enable_reduction &&
                  (cart.item.totals.promotion !== 0 ? (
                    <div className={classes.rowBorder}>
                      <Typography variant={'body2'}>
                        <CMSText
                          asText
                          data={basket?.data?.reduction}
                          defaultText={t('texts:basket:reduction')}
                        />
                        &nbsp;
                      </Typography>
                      <div className={classes.left}>
                        <Typography
                          variant={'body2'}
                          className={clsx('basket__discount', classes.green)}
                        >
                          <b>
                            <Price
                              amount={cart.item.totals.promotion}
                              showDayOrMonth={false}
                              dayOrMonth={settings?.day_or_month}
                              dayOrMonthShort={settings?.day_or_month_short}
                            />
                          </b>
                        </Typography>
                      </div>
                    </div>
                  ) : (
                    <div className={classes.rowBorder}>
                      <Typography variant={'body2'}>
                        <CMSText
                          asText
                          data={basket?.data?.reduction_text_explication}
                          defaultText={t(
                            'texts:basket:reduction_text_explication'
                          )}
                        />
                      </Typography>
                    </div>
                  ))}
                {/* Gift Card */}
                {user?.balance?.balance > 0 && (
                  <>
                    <div className={classes.rowBorder}>
                      <Typography variant={'body2'}>
                        <CMSText
                          asText
                          data={basket?.data?.gift_card_text}
                          defaultText={t('texts:basket:gift_card_text')}
                        />
                      </Typography>
                      <div className={classes.left}>
                        <Typography
                          variant={'body2'}
                          className={clsx('basket__discount', classes.green)}
                        >
                          <b>
                            <Price
                              amount={user?.balance?.balance}
                              showDayOrMonth={false}
                              dayOrMonth={settings?.day_or_month}
                              dayOrMonthShort={settings?.day_or_month_short}
                            />
                          </b>
                        </Typography>
                      </div>
                    </div>
                    <div
                      className={clsx(
                        classes.giftCardExplanation,
                        'basket-giftCard-explanation'
                      )}
                    >
                      <InfoIcon
                        fontSize="small"
                        className={classes.giftCardIcon}
                      />
                      <CMSText
                        asText
                        data={basket?.data?.gift_card_explanation}
                        defaultText={t('texts:basket:gift_card_explanation')}
                      />
                    </div>
                  </>
                )}
              </div>
            </Collapse>
          </>
        )}
        {/* Total */}
        {(!showDetailsOnBuyAbleProducts || mixedBasket || !isSecondHand) && (
          <>
            <div className={clsx(classes.rowBorder, classes.rowNoBorder)}>
              <Typography variant={'body2'}>
                {basket?.data?.total && (
                  <CMSText
                    asText
                    data={basket?.data?.total}
                    defaultText={t('texts:basket:total')}
                  />
                )}
                &nbsp;
                {isBuyAbleProducts ? (
                  <>
                    <CMSText
                      asText
                      data={basket?.data?.buytotal}
                      defaultText={t('texts:basket:buyTotal')}
                    />
                    &nbsp;
                    {!isEndFunnel && !notDisplayWithoutShipping && (
                      <span className={classes.shipping}>
                        <CMSText
                          asText
                          data={basket?.data?.without_shipping}
                          defaultText={t('texts:basket:withoutShipping')}
                        />
                      </span>
                    )}
                  </>
                ) : (
                  <>
                    {!isSecondHand && mixedBasket && (
                      <>
                        <CMSText
                          asText
                          data={basket?.data?.renttotal}
                          defaultText={t('texts:basket:rentTotal')}
                        />
                        &nbsp;
                      </>
                    )}
                    {!isEndFunnel && !notDisplayWithoutShipping && (
                      <span className={classes.shipping}>
                        <CMSText
                          asText
                          data={basket?.data?.without_shipping}
                          defaultText={t('texts:basket:withoutShipping')}
                        />
                      </span>
                    )}
                  </>
                )}
              </Typography>
              <div className={classes.left}>
                <Typography
                  variant={'body2'}
                  color={'secondary'}
                  className={clsx('cart__total')}
                >
                  <b>
                    {!isBuyAbleProducts ? (
                      <Price
                        amount={amountTotal}
                        showDayOrMonth={false}
                        dayOrMonth={settings?.day_or_month}
                        dayOrMonthShort={settings?.day_or_month_short}
                      />
                    ) : mixedBasket ? (
                      <Price
                        amount={totalWithoutBuyAble}
                        showDayOrMonth={false}
                        dayOrMonth={settings?.day_or_month}
                        dayOrMonthShort={settings?.day_or_month_short}
                      />
                    ) : (
                      <Price
                        amount={amountTotal}
                        showDayOrMonth={false}
                        dayOrMonth={settings?.day_or_month}
                        dayOrMonthShort={settings?.day_or_month_short}
                      />
                    )}
                  </b>
                </Typography>
              </div>
            </div>
            {deposit > 0 && (
              <div className={clsx(classes.rowBorder, classes.rowNoBorder)}>
                <Typography variant={'body2'} className={classes.deposit}>
                  <CMSText
                    asText
                    data={basket?.data?.deposit}
                    defaultText={t('texts:basket:deposit')}
                  />
                  &nbsp;
                  <Tooltip
                    title={t('texts:basket:deposit_explanation')}
                    placement="top"
                  >
                    <InfoIcon fontSize="small" />
                  </Tooltip>
                </Typography>
                <div className={classes.left}>
                  <Typography variant={'body2'}>
                    <b>
                      <Price
                        amount={depositAmount}
                        showDayOrMonth={false}
                        dayOrMonth={settings?.day_or_month}
                        dayOrMonthShort={settings?.day_or_month_short}
                      ></Price>
                    </b>
                  </Typography>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  )
}

export default BasketTotalsDad
