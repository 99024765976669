import React, { ReactNode, useEffect, useMemo, useState } from 'react'
import clsx from 'clsx'
import { connect } from 'react-redux'
import Link from '../Link/Link'
import dayjs from 'dayjs'
import {
  Backdrop,
  Checkbox,
  Drawer,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core'
import Button from '../Button/Button'
import BasketProductRow from '../Shop/BasketProductRow'
import ConditionalWrap from '../Global/ConditionalWrap'
import ShippingInfos from '../ShippingInfos/ShippingInfos'
import {
  Close,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from '@material-ui/icons'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import BasketTotalsDad from '../Shop/BasketTotalsDad'
import BasketTotalsSub from '../Shop/BasketTotalsSub'
import { useMatomo } from '@datapunt/matomo-tracker-react'
import {
  checkStockQuantity,
  checkTryOutError,
  isPurchasedItem,
  numberToPrice,
} from '../../utils/utils'
import CMSText, { isPrismicText } from '../Global/CMSText'
import { useRouter } from 'next/router'
import Price from '../Shop/Price'
import {
  isClassicSub,
  isFlex,
  isSecondHandOnly,
  isSubscription,
} from '../../utils/constants'
import useDrawerStyles from '../Layout/Drawer.style'
import { GAEvent, LayoutStyle } from '../../typings/base'
import { setShowBasket } from '../../redux/actions/basket'
import BasketTotalsSecondHand from '../Shop/BasketTotalsSecondHand'
import * as gtag from '../../utils/gtag'
import {
  clearItemVariantFromGAParamsIfNotChalhoub,
  getCheckoutGtmData,
  isChalhoub,
  safeDataLayerPush,
} from '../../utils/gtag'
import { useCart } from '../../hooks/useCart'
import { useTranslation } from 'react-i18next'
import { RootState } from '../../redux/reducers/rootReducer'
import { useBasketStyles } from './Basket.styles'
import { BasketButton } from './BasketButton'
import { PrismicBasket } from '../../interfaces/common/prismicBasket'

type TProps = ReturnType<typeof mapStateToProps> & {
  nextStep?: string
  isBasketOpen: boolean
  isAbsolute?: boolean
  children?: ReactNode
  promotions: any
  basket: PrismicBasket
  contract: any
  settings?: any
  hidePrice?: boolean
  showSizes?: boolean
  isProductSticky?: boolean
  layoutStyle: LayoutStyle
  takeback: any
  user?: any
  setShowBasket: (isOpen: boolean) => void
}

const mapStateToProps = (state: RootState) => {
  const showBasket = state?.basket?.showBasketDetails
  return {
    cart: state.cart,
    stock: state.stock,
    promotions: state.promotions,
    contract: state.contract,
    user: state.user,
    isBasketOpen: showBasket,
    parameters: state.parameters,
  }
}

const mapDispatchToProps = {
  setShowBasket: setShowBasket,
}

const MainTooltip = withStyles((theme) => ({
  tooltip: {
    textAlign: 'center',
    padding: theme.spacing(1),
    lineHeight: 1,
    '&:hover, &:focus': {
      cursor: 'default',
    },
  },
}))(Tooltip)

const Basket = ({
  nextStep,
  promotions,
  setShowBasket,
  isBasketOpen,
  basket,
  contract,
  stock,
  isAbsolute,
  cart,
  parameters,
  settings,
  hidePrice,
  showSizes,
  layoutStyle,
  takeback,
  user,
}: TProps) => {
  const { deleteCart } = useCart()
  const { t } = useTranslation()
  const classes = useBasketStyles(layoutStyle)
  const drawerClasses = useDrawerStyles()
  const { trackEvent, pushInstruction } = useMatomo()
  const isCart =
    typeof cart.item !== 'undefined' &&
    cart.item.items &&
    cart.item.items.length > 0
  const [errorArray, setErrorArray] = useState([])
  const [errorStock, setErrorStock] = useState(false)
  const [hasTryOutError, setHasTryOutError] = useState(false)
  const [
    numberOfDaysLeftInCurrentSubscription,
    setNumberOfDaysLeftInCurrentSubscription,
  ] = useState(30)
  const [currentUrl, setCurrentUrl] = useState('')
  const notDisplayWithoutShipping = basket?.data?.not_display_without_shipping
  const basketIcon = basket?.data?.basketbutton
  const enableEmptyCartButton = basket?.data?.enable_empty_cart_button
  const displayFlexPrice = settings?.display_flex_price_a_day
  const router = useRouter()
  const isFunnel = ['/information', '/shipping', '/payment'].includes(
    router.pathname
  )
  const isEndFunnel =
    router.route === '/shipping' || router.route === '/payment'
  const [amountTotal, setAmountTotal] = useState(0)
  const start = dayjs(cart?.item?.startLeasingDate, 'DD/MM/YYYY')
  const end = dayjs(cart?.item?.endLeasingDate, 'DD/MM/YYYY')
  const [dayRange, setDayRange] = useState(end.diff(start, 'day') + 1)
  const locked = cart.item && cart.item.items && cart.item.items.length > 0

  const {
    isSecondHand,
    buyableProductsQuantity,
    productsCount,
    buyAbleProducts,
    rentAbleProducts,
    secondHandProductsList,
    rentableProductsQuantity,
    secondHandProductsQuantity,
    mixedBasket,
  } = useMemo(() => {
    const buyAbleProducts = []
    const rentAbleProducts = []
    const secondHandProducts = []

    let rentableProductsQuantity = 0
    let buyableProductsQuantity = 0
    let secondHandProductsQuantity = 0
    let productsCount = 0

    let isSecondHand = false
    cart?.item?.items?.map((product) => {
      const quantity = product.quantity
      productsCount += quantity
      if (product.type === 'SECOND_HAND') {
        // Check if we at least have one second hand item in the cart to determine the basket type
        isSecondHand = true
      }
      if (product.product.variants[0].purchase) {
        buyAbleProducts.push(product)
        buyableProductsQuantity += quantity
      } else if (Object.keys(product.units[0] || {}).includes('puid')) {
        // For some reasons there's no image being sent back in the cart request for secondHand products
        const images = stock.stock?.find((el) => {
          return !!product.product.variants?.find(
            (variant) => el.lizeeSKU === variant.sku
          )
        })?.secondHand?.[0]?.images

        if (product.product.images.length === 0 && images) {
          product.product.images = images as any
        }

        secondHandProducts.push(product)
        secondHandProductsQuantity += quantity
      } else {
        rentAbleProducts.push(product)
        rentableProductsQuantity += quantity
      }
    })

    return {
      isSecondHand,
      productsCount,
      rentableProductsQuantity,
      buyableProductsQuantity,
      secondHandProductsQuantity,
      buyAbleProducts,
      rentAbleProducts,
      secondHandProductsList: secondHandProducts,
      mixedBasket: buyAbleProducts?.length > 0 && rentAbleProducts?.length > 0,
    }
  }, [cart.item, cart?.item?.items, stock])

  const [showTooltip, setShowTooltip] = useState(false)
  const isEnableChooseStartingDate = parameters?.items?.enableChooseStartingDate
  const showBasketWording =
    settings?.my_basket && settings?.my_basket?.length !== 0

  const [basketValidation, setBasketValidation] = useState(false)
  const secondHandProductValidationText =
    basket?.data?.checkbox_second_hand_product_validation
  const changeBasketValidation = () => {
    setBasketValidation(!basketValidation)
  }
  useEffect(() => {
    if (router.isReady) {
      setCurrentUrl(router.asPath)
    }
  }, [router.isReady])

  useEffect(() => {
    isEndFunnel
      ? setAmountTotal(cart?.item?.totals?.total)
      : setAmountTotal(cart?.item?.totals?.items)
  }, [cart?.item])

  useEffect(() => {
    setHasTryOutError(checkTryOutError(cart))
    const isHalfDayRange =
      cart?.item?.oneDayDuration === 'afternoon' ||
      cart?.item?.oneDayDuration === 'morning'
    isHalfDayRange ? setDayRange(0.5) : setDayRange(end.diff(start, 'day') + 1)
  }, [cart?.item])

  const toggleBasketDetails = () => {
    setShowBasket(!isBasketOpen)
    trackEvent({
      category: 'Basket',
      action: `Open Basket`,
    })
  }
  const handleClose = () => {
    setShowBasket(false)
  }

  useEffect(() => {
    // if price per day, we need to extract buyable product pric to multiplie it by 30 so it is divided back on the total
    if (mixedBasket && displayFlexPrice) {
      let AmountTotalWithBuyable = 0
      cart?.item?.items?.map((product) => {
        if (!isPurchasedItem(product)) {
          AmountTotalWithBuyable += !displayFlexPrice
            ? product.total
            : product.total * 30
        }
      })
      if (contract?.items?.length > 0) {
        AmountTotalWithBuyable += contract?.items[0].totalWithoutBoughtProducts
      }
      setAmountTotal(AmountTotalWithBuyable)
    }
  }, [mixedBasket])

  useEffect(() => {
    const newArr = []
    isCart &&
      cart.item.items?.map((product) => {
        const availability = checkStockQuantity(
          stock,
          product.product.variants[0],
          'basket'
        )
        if (product.quantity > availability) {
          newArr.push({ error: true, code: product.product.variants[0].code })
        }
      })
    if (newArr.length > 0) {
      isSecondHand ? setErrorStock(false) : setErrorStock(true)
    } else {
      setErrorStock(false)
    }
    setErrorArray(newArr)
  }, [stock?.stockBasket])

  const emptyCart = async () => {
    deleteCart()

    trackEvent({
      category: 'Basket',
      action: `Empty Basket`,
    })

    pushInstruction('clearEcommerceCart')
    pushInstruction(
      'trackEcommerceCartUpdate',
      isSubscription
        ? numberToPrice(cart?.item?.totals?.totalWithCart)
        : numberToPrice(cart?.item?.totals?.total)
    )
  }

  const trackContinue = () => {
    trackEvent({
      category: 'Basket',
      action: `Initiate Checkout`,
      name: `Click on Continue on ${currentUrl}`,
      value: numberToPrice(cart.item.totals.total),
    })

    const gaParams = {
      currency: cart.item.currency,
      value: numberToPrice(cart.item.totals.items),
      items: cart.item.items.map(({ product, quantity, total }) => {
        const firstVariant = Object.values(product?.variants)[0]
        return {
          ['item_name']: product?.name,
          ['item_id']: isChalhoub ? firstVariant.sku : product?.code,
          ['item_variant']: isChalhoub && firstVariant.code,
          price: numberToPrice(total),
          quantity,
        }
      }),
    }

    const cleanGAParams = clearItemVariantFromGAParamsIfNotChalhoub(gaParams)
    gtag.tracker(GAEvent.BEGIN_CHECKOUT, cleanGAParams)
    safeDataLayerPush(getCheckoutGtmData(cart))

    setShowBasket(false)
  }

  const RedirectToFunnel = () => {
    trackEvent({
      category: 'Basket',
      action: `Continue from Basket to Informations`,
    })
    setShowBasket(false)
  }

  useEffect(() => {
    if (productsCount === 0) {
      setShowBasket(false)
    }
  }, [productsCount])

  useEffect(() => {
    if (displayFlexPrice && cart?.item?.items?.length > 0) {
      setNumberOfDaysLeftInCurrentSubscription(
        dayjs(cart?.item?.endLeasingDate, 'DD/MM/YYYY').diff(
          dayjs(cart?.item?.startLeasingDate, 'DD/MM/YYYY'),
          'day'
        )
      )
    }
  }, [cart])

  return (
    <div
      id={`basket--${isAbsolute ? 'cart' : 'funnel'}`}
      className={clsx('basket', [classes.root, isFunnel && classes.fullHeight])}
    >
      {isAbsolute &&
        (productsCount === 0 ? (
          <MainTooltip
            title={t('texts:basket:empty')}
            placement="bottom"
            open={showTooltip}
            enterDelay={2000}
            classes={{ tooltip: 'cart-tooltip' }}
          >
            <span
              className={classes.basketBtnTooltip}
              onMouseEnter={() => setShowTooltip(true)}
              onMouseLeave={() => setShowTooltip(false)}
              role="button"
            >
              <BasketButton
                isFunnel={isFunnel}
                productsCount={productsCount}
                layoutStyle={layoutStyle}
                toggleBasketDetails={toggleBasketDetails}
                basketIcon={basketIcon}
                showBasketWording={showBasketWording}
                settings={settings}
              />
            </span>
          </MainTooltip>
        ) : (
          <BasketButton
            isFunnel={isFunnel}
            productsCount={productsCount}
            layoutStyle={layoutStyle}
            toggleBasketDetails={toggleBasketDetails}
            basketIcon={basketIcon}
            showBasketWording={showBasketWording}
            settings={settings}
          />
        ))}

      <ConditionalWrap
        condition={isAbsolute}
        wrap={(children: React.ReactNode) => (
          <>
            <Drawer
              id="cart-menu"
              open={isBasketOpen}
              onClose={handleClose}
              anchor={'right'}
              className={clsx(drawerClasses.drawer)}
            >
              {children}
            </Drawer>
            <Backdrop className={classes.backdrop} open={isBasketOpen} />
          </>
        )}
      >
        <div
          className={clsx(
            isAbsolute ? drawerClasses.drawerLayout : classes.basket
          )}
        >
          {isAbsolute && (
            <div className={clsx(drawerClasses.drawerHeader)}>
              <Typography
                variant={'h3'}
                component={'div'}
                color={'primary'}
                className="basket__popup__title"
              >
                <CMSText
                  asText
                  data={basket?.data?.popup_title}
                  defaultText={t('texts:basket:drawer:title')}
                />
              </Typography>
              <Button
                size={'small'}
                color={'default'}
                disableRipple
                endIcon={<Close />}
                onClick={() => handleClose()}
                className={drawerClasses.drawerClose}
                disableTouchRipple
                disableElevation
              >
                <CMSText
                  asText
                  data={basket?.data?.popup_close}
                  defaultText={t('texts:basket:drawer:close')}
                />
              </Button>
            </div>
          )}
          <div
            className={clsx(
              'cart',
              isAbsolute && [drawerClasses.drawerContent, classes.basketBG]
            )}
          >
            <div
              className={clsx(
                isAbsolute && classes.basketContent,
                isSecondHand &&
                  !isSecondHandOnly &&
                  !isAbsolute &&
                  classes.basketProductsFunnel
              )}
            >
              {/* Errors */}
              {errorStock && (
                <div className={classes.row}>
                  <Typography
                    variant={'caption'}
                    color={'error'}
                    align={'center'}
                    gutterBottom
                  >
                    {t('texts:basket:error_stock')}
                  </Typography>
                </div>
              )}
              {hasTryOutError && (
                <div className={classes.row}>
                  <Typography
                    variant={'caption'}
                    color={'error'}
                    align={'center'}
                    gutterBottom
                  >
                    {t('texts:basket:error_tryout')}
                  </Typography>
                </div>
              )}
              <div className={classes.row}>
                {!isSubscription &&
                  !isSecondHandOnly &&
                  rentAbleProducts?.length !== 0 && (
                    <div
                      className={
                        !isAbsolute
                          ? `cart-shipping ${classes.rowShipping}`
                          : 'cart-shipping cart-shipping--absolute'
                      }
                    >
                      <ShippingInfos
                        startDate={start}
                        endDate={end}
                        deliverDuration={parameters?.items?.deliverDuration}
                        returnDuration={parameters?.items?.returnDuration}
                        loading={parameters?.loading}
                        isExpress={
                          cart?.item?.shipments?.length > 0 &&
                          cart?.item?.shipments[0]?.method?.isExpress
                        }
                        t={t}
                        text={basket?.data}
                        small={isAbsolute}
                        isEnableChooseStartingDate={isEnableChooseStartingDate}
                      />
                    </div>
                  )}
              </div>
              {rentAbleProducts.length > 0 && (
                <>
                  <div
                    className={clsx(
                      classes.basketProducts,
                      isAbsolute && classes.basketProductsCart,
                      !isSecondHand &&
                        !isAbsolute &&
                        classes.basketProductsFunnel
                    )}
                  >
                    <div
                      className={clsx('cart-list', classes.basketProductsList)}
                    >
                      <Typography
                        variant="h6"
                        component="div"
                        className={clsx('cart-count', classes.count)}
                      >
                        {rentableProductsQuantity > 1
                          ? rentableProductsQuantity +
                            ' ' +
                            t('texts:basket:products')
                          : rentableProductsQuantity +
                            ' ' +
                            t('texts:basket:product')}
                      </Typography>
                      {rentAbleProducts?.map((product) => {
                        const isAvailable =
                          typeof errorArray.find(
                            (el) => el.code === product.product.variants[0].code
                          ) !== 'undefined'
                        const availability = checkStockQuantity(
                          stock,
                          product.product.variants[0],
                          'basket'
                        )

                        return (
                          <BasketProductRow
                            key={`product-${product.id}`}
                            id={product.id}
                            t={t}
                            stock={stock}
                            basket={basket}
                            productCode={product.product.code}
                            variant={product.product.variants[0]}
                            isTryOut={product.withTryingOn}
                            isSki={product?.ski}
                            dayRange={dayRange}
                            name={product.product.name}
                            photoThumb={product?.product?.images[0]}
                            priceRental={product.total / 100}
                            availability={availability}
                            isAvailable={isAvailable}
                            locked={locked}
                            loading={cart.loading}
                            hidePrice={hidePrice}
                            showSizes={showSizes}
                            settings={settings}
                          />
                        )
                      })}
                    </div>
                  </div>
                  <div>
                    {!errorStock && !hasTryOutError && (
                      <>
                        {isCart && !isSubscription && (
                          <BasketTotalsDad
                            t={t}
                            cart={cart}
                            basket={basket}
                            dayRange={dayRange}
                            settings={settings}
                            mixedBasket={mixedBasket}
                            user={user}
                            showDetailsOnBuyAbleProducts
                            isSecondHand={isSecondHand}
                            parameters={parameters}
                          />
                        )}
                        {isCart && isSubscription && (
                          <BasketTotalsSub
                            basket={basket}
                            t={t}
                            numberOfDaysLeftInCurrentSubscription={
                              numberOfDaysLeftInCurrentSubscription
                            }
                            total={cart.item.totals.totalItemWithoutPromotion}
                            settings={settings}
                            isBuyAbleProducts={false}
                            mixedBasket={mixedBasket}
                            showDetailsOnBuyAbleProducts
                            user={user}
                          />
                        )}
                      </>
                    )}
                  </div>
                </>
              )}
              {secondHandProductsList?.length > 0 && (
                <div className={classes.basketProducts}>
                  <div
                    className={clsx(
                      'cart-list',
                      classes.basketProductsList,
                      isAbsolute && classes.basketProductsCart,
                      isSecondHand &&
                        isSecondHandOnly &&
                        !isAbsolute &&
                        classes.basketProductsFunnel
                    )}
                  >
                    <Typography
                      variant="h6"
                      component="div"
                      className={clsx('cart-count', classes.count)}
                    >
                      {secondHandProductsQuantity > 1
                        ? secondHandProductsQuantity +
                          ' ' +
                          t('texts:basket:products')
                        : secondHandProductsQuantity +
                          ' ' +
                          t('texts:basket:product')}
                    </Typography>
                    {secondHandProductsList.map((product) => {
                      return product.units.map((unit) => {
                        return (
                          <BasketProductRow
                            key={`product-${product.id}`}
                            id={product.id}
                            t={t}
                            stock={stock}
                            basket={basket}
                            buyAble={true}
                            isSki={product?.ski}
                            productCode={product.product.code}
                            variant={product.product.variants[0]}
                            isTryOut={product.withTryingOn}
                            dayRange={dayRange}
                            name={product.product.name}
                            photoThumb={product?.product?.images[0]}
                            locked={locked}
                            loading={cart.loading}
                            hidePrice={hidePrice}
                            showSizes={showSizes}
                            settings={settings}
                            isSecondHand={isSecondHand}
                            unit={unit}
                            takeback={takeback}
                          />
                        )
                      })
                    })}
                  </div>
                  <BasketTotalsSecondHand
                    t={t}
                    cart={cart}
                    basket={basket}
                    settings={settings}
                    user={user}
                  />
                </div>
              )}
              {buyAbleProducts.length > 0 && (
                <div className={classes.basketProducts}>
                  <div
                    className={clsx('cart-list', classes.basketProductsList)}
                  >
                    <Typography
                      variant="h6"
                      component="div"
                      className={clsx('cart-count', classes.count)}
                    >
                      {buyAbleProducts.length === 0
                        ? 'panier vide'
                        : buyableProductsQuantity > 1
                        ? buyableProductsQuantity +
                          ' ' +
                          t('texts:basket:products')
                        : buyableProductsQuantity +
                          ' ' +
                          t('texts:basket:product')}
                    </Typography>
                    {buyAbleProducts?.map((product) => {
                      const isAvailable =
                        typeof errorArray.find(
                          (el) => el.code === product.product.variants[0].code
                        ) !== 'undefined'
                      const availability = checkStockQuantity(
                        stock,
                        product.product.variants[0],
                        'basket'
                      )

                      return (
                        <BasketProductRow
                          key={`product-${product.id}`}
                          id={product.id}
                          t={t}
                          stock={stock}
                          basket={basket}
                          buyAble={true}
                          isSki={product?.ski}
                          productCode={product.product.code}
                          variant={product.product.variants[0]}
                          isTryOut={product.withTryingOn}
                          dayRange={dayRange}
                          name={product.product.name}
                          photoThumb={product?.product?.images[0]}
                          priceRental={product.total / 100}
                          availability={availability}
                          isAvailable={isAvailable}
                          locked={locked}
                          loading={cart.loading}
                          hidePrice={hidePrice}
                          showSizes={showSizes}
                          settings={settings}
                        />
                      )
                    })}
                  </div>
                  {isCart && !isSubscription && (
                    <BasketTotalsDad
                      t={t}
                      cart={cart}
                      basket={basket}
                      dayRange={dayRange}
                      mixedBasket={mixedBasket}
                      isBuyAbleProducts={true}
                      showDetailsOnBuyAbleProducts={
                        rentAbleProducts.length === 0
                      }
                      settings={settings}
                      user={user}
                      parameters={parameters}
                    />
                  )}
                  {isCart && isSubscription && (
                    <BasketTotalsSub
                      basket={basket}
                      t={t}
                      numberOfDaysLeftInCurrentSubscription={
                        numberOfDaysLeftInCurrentSubscription
                      }
                      total={cart.item.totals.totalItemWithoutPromotion}
                      settings={settings}
                      mixedBasket={mixedBasket}
                      isBuyAbleProducts={true}
                      showDetailsOnBuyAbleProducts={
                        rentAbleProducts.length === 0
                      }
                      user={user}
                    />
                  )}
                </div>
              )}
              {isPrismicText(basket?.data?.link_to_product_card) && (
                <Typography
                  variant={'body2'}
                  component={'div'}
                  className={classes.basketLinkToProductCard}
                >
                  <CMSText data={basket?.data?.link_to_product_card} />
                </Typography>
              )}
            </div>
          </div>
          <div
            className={clsx(
              isAbsolute && [drawerClasses.drawerFooter, classes.basketFooter]
            )}
          >
            {isCart &&
              !isSubscription &&
              !mixedBasket &&
              rentAbleProducts.length > 0 &&
              !isFunnel &&
              !isSecondHand && (
                <BasketTotalsDad
                  t={t}
                  cart={cart}
                  basket={basket}
                  dayRange={dayRange}
                  settings={settings}
                  user={user}
                  parameters={parameters}
                />
              )}
            {isCart &&
              isSubscription &&
              !mixedBasket &&
              rentAbleProducts.length > 0 &&
              !isFunnel && (
                <BasketTotalsSub
                  basket={basket}
                  t={t}
                  numberOfDaysLeftInCurrentSubscription={
                    numberOfDaysLeftInCurrentSubscription
                  }
                  total={cart.item.totals.totalItemWithoutPromotion}
                  settings={settings}
                  isBuyAbleProducts={false}
                  user={user}
                />
              )}
            {/* Basket TOTAL BUYABLE only */}
            {buyAbleProducts.length > 0 && rentAbleProducts.length === 0 && (
              <div
                className={clsx(
                  classes.footerRow,
                  isAbsolute && classes.basketTotalMixed
                )}
              >
                <Typography
                  variant={'body1'}
                  align={'center'}
                  className={classes.bold}
                >
                  <CMSText
                    asText
                    data={basket?.data?.buytotal}
                    defaultText={t('texts:basket:buyTotal')}
                  />
                  &nbsp;
                  {!isEndFunnel && !notDisplayWithoutShipping && (
                    <span className={classes.shipping}>
                      <CMSText
                        asText
                        data={basket?.data?.without_shipping}
                        defaultText={t('texts:basket:withoutShipping')}
                      />
                    </span>
                  )}
                </Typography>
                {!isSubscription ? (
                  // Date to Date
                  <Typography
                    variant={'body1'}
                    align={'center'}
                    className={clsx(
                      classes.bold,
                      'cart-total',
                      'cart-total--dad'
                    )}
                  >
                    <Price
                      amount={amountTotal}
                      dayOrMonth={settings?.day_or_month}
                      dayOrMonthShort={settings?.day_or_month_short}
                      showDayOrMonth={false}
                      displayFlexPrice={displayFlexPrice}
                    />
                  </Typography>
                ) : (
                  // Subscription
                  <div>
                    <Typography
                      variant={'body1'}
                      align={'right'}
                      className={classes.totalDetails}
                    >
                      <Price
                        amount={
                          isFlex
                            ? cart.item.totals.total -
                              cart.item.totals.totalWithoutBoughtProducts -
                              cart?.item?.totals?.shipping
                            : isEndFunnel
                            ? cart.item.totals.total
                            : cart.item.totals.totalItemWithoutPromotion
                        }
                        dayOrMonth={settings?.day_or_month}
                        dayOrMonthShort={settings?.day_or_month_short}
                        showDayOrMonth={false}
                        buyAble={true}
                        displayFlexPrice={displayFlexPrice}
                      />
                    </Typography>
                  </div>
                )}
              </div>
            )}
            {/*Basket TOTAL MIXED*/}
            {buyAbleProducts.length > 0 && rentAbleProducts.length > 0 && (
              <div
                className={clsx(
                  classes.footerRow,
                  isAbsolute && classes.basketTotalMixed
                )}
              >
                <Typography
                  variant={'body1'}
                  align={'center'}
                  className={classes.bold}
                >
                  <CMSText
                    asText
                    data={basket?.data?.total}
                    defaultText={t('texts:basket:total')}
                  />
                  &nbsp;
                  {!isEndFunnel && !notDisplayWithoutShipping && (
                    <span className={classes.shipping}>
                      <CMSText
                        asText
                        data={basket?.data?.without_shipping}
                        defaultText={t('texts:basket:withoutShipping')}
                      />
                    </span>
                  )}
                </Typography>
                {!isSubscription ? (
                  // Date to Date
                  <Typography
                    variant={'body1'}
                    align={'center'}
                    className={classes.bold}
                  >
                    <Price
                      amount={amountTotal}
                      dayOrMonth={settings?.day_or_month}
                      dayOrMonthShort={settings?.day_or_month_short}
                      showDayOrMonth={false}
                      displayFlexPrice={displayFlexPrice}
                    />
                  </Typography>
                ) : (
                  // Subscription
                  <div>
                    <Typography
                      variant={'body1'}
                      align={'right'}
                      className={classes.totalDetails}
                    >
                      <Price
                        amount={
                          isFlex
                            ? cart?.item?.totals?.totalWithoutBoughtProducts
                            : cart?.item?.totals?.itemsTotalWithCart -
                              cart?.item?.totals?.totalItemWithoutPromotion
                        }
                        dayOrMonth={settings?.day_or_month}
                        dayOrMonthShort={settings?.day_or_month_short}
                        showDayOrMonth={true}
                        numberOfDays={numberOfDaysLeftInCurrentSubscription}
                        isShort={true}
                        displayFlexPrice={displayFlexPrice}
                      />
                      &nbsp;+&nbsp;
                      <Price
                        amount={
                          isFlex
                            ? cart.item.totals.total -
                              cart.item.totals.totalWithoutBoughtProducts -
                              cart?.item?.totals?.shipping
                            : isEndFunnel
                            ? cart.item.totals.total
                            : cart.item.totals.totalItemWithoutPromotion
                        }
                        dayOrMonth={settings?.day_or_month}
                        dayOrMonthShort={settings?.day_or_month_short}
                        showDayOrMonth={false}
                        buyAble={true}
                        displayFlexPrice={displayFlexPrice}
                      />
                    </Typography>
                  </div>
                )}
              </div>
            )}
            {/* Basket TOTAL RENT/SECOND HAND and TOTAL SECOND HAND */}
            {isSecondHand && (
              <>
                <div>
                  {basket?.data?.enable_shipping && (
                    <>
                      {isEndFunnel && (
                        <div
                          className={clsx(
                            classes.row,
                            isAbsolute && classes.basketTotalMixed
                          )}
                        >
                          <Typography variant={'body2'}>
                            <CMSText
                              asText
                              data={basket?.data?.shipping}
                              defaultText={t('texts:basket:shipping')}
                            />
                          </Typography>
                          <div className={classes.left}>
                            <Typography variant={'body2'}>
                              <b>
                                {cart?.item?.totals?.shipping === 0 ? (
                                  <CMSText
                                    asText
                                    data={basket?.data?.free}
                                    defaultText={t('texts:basket:free')}
                                  />
                                ) : (
                                  <Price
                                    amount={cart?.item?.totals?.shipping}
                                    showDayOrMonth={false}
                                    dayOrMonth={settings?.day_or_month}
                                    dayOrMonthShort={
                                      settings?.day_or_month_short
                                    }
                                  />
                                )}
                              </b>
                            </Typography>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
                <div
                  className={clsx(
                    classes.footerRow,
                    isAbsolute && classes.basketTotalMixed
                  )}
                >
                  <Typography
                    variant={'body1'}
                    align={'center'}
                    className={classes.bold}
                  >
                    <CMSText
                      asText
                      data={basket?.data?.total}
                      defaultText={t('texts:basket:total')}
                    />
                    &nbsp;
                    {!isEndFunnel && !notDisplayWithoutShipping && (
                      <span className={classes.shipping}>
                        <CMSText
                          asText
                          data={basket?.data?.without_shipping}
                          defaultText={t('texts:basket:withoutShipping')}
                        />
                      </span>
                    )}
                  </Typography>
                  <Typography
                    variant={'body1'}
                    align={'center'}
                    className={classes.bold}
                  >
                    <Price
                      amount={amountTotal}
                      dayOrMonth={settings?.day_or_month}
                      dayOrMonthShort={settings?.day_or_month_short}
                      showDayOrMonth={false}
                      displayFlexPrice={displayFlexPrice}
                    />
                  </Typography>
                </div>
              </>
            )}
            {/* Basket Footer for Flex Sub (engagment and debit) */}
            {isSubscription && (
              <div
                className={clsx(
                  classes.alignCenter,
                  classes.rowFooterSub,
                  'cart-flexSub-text'
                )}
              >
                <Typography
                  component={'p'}
                  variant="caption"
                  className={classes.smallText}
                >
                  {isFlex && (
                    <>
                      <CMSText
                        asText
                        data={basket?.data?.engagement}
                        defaultText={t('texts:basket:engagement')}
                      />
                      <br />
                    </>
                  )}
                  <CMSText
                    asText
                    data={basket?.data?.debit}
                    defaultText={t('texts:basket:debit')}
                  />
                </Typography>
              </div>
            )}
            {locked && nextStep && (
              <>
                {isSecondHand && secondHandProductValidationText?.length > 0 && (
                  <div className={classes.basketCheckboxValidation}>
                    <Checkbox
                      edge="start"
                      checked={basketValidation}
                      onChange={changeBasketValidation}
                      disableRipple
                    />
                    <Typography variant={'body2'} component={'div'}>
                      <CMSText asText data={secondHandProductValidationText} />
                    </Typography>
                  </div>
                )}
                <div className={classes.basketActions}>
                  {isAbsolute && (
                    <Button
                      color={'default'}
                      size={'small'}
                      disableRipple
                      className={clsx('cart-reset', classes.reset)}
                      onClick={handleClose}
                      startIcon={<KeyboardArrowLeft />}
                    >
                      <CMSText
                        asText
                        data={basket?.data.continue_shop}
                        defaultText={t('texts:basket:continue_shop')}
                      />
                    </Button>
                  )}
                  {productsCount !== 0 && !errorStock && !hasTryOutError ? (
                    <>
                      {isClassicSub && promotions?.futurPrice === 0 ? (
                        <Button
                          className={clsx('cart-next', classes.next)}
                          data-testid={`continue`}
                          variant={'contained'}
                          color={'primary'}
                          size={'small'}
                          disabled
                          onClick={() => RedirectToFunnel()}
                        >
                          <CMSText
                            asText
                            data={basket.data.continue}
                            defaultText={t('texts:basket:continue')}
                          />
                        </Button>
                      ) : (
                        <>
                          <Link href={nextStep}>
                            <a
                              style={{
                                pointerEvents:
                                  secondHandProductValidationText &&
                                  secondHandProductValidationText.length > 0 &&
                                  isSecondHand &&
                                  !basketValidation
                                    ? 'none'
                                    : 'auto',
                              }}
                            >
                              <Button
                                className={clsx('cart-next', classes.next)}
                                data-testid={`continue`}
                                variant={'contained'}
                                color={'primary'}
                                size={'small'}
                                disabled={
                                  contract?.loading ||
                                  (secondHandProductValidationText &&
                                    secondHandProductValidationText.length >
                                      0 &&
                                    isSecondHand &&
                                    !basketValidation)
                                }
                                onClick={() => trackContinue()}
                                endIcon={<KeyboardArrowRight />}
                              >
                                <CMSText
                                  asText
                                  data={basket?.data?.continue}
                                  defaultText={t('texts:basket:continue')}
                                />
                              </Button>
                            </a>
                          </Link>
                        </>
                      )}
                    </>
                  ) : (
                    <Button disabled variant="contained">
                      <CMSText
                        asText
                        data={basket.data.continue}
                        defaultText={t('texts:basket:continue')}
                      />
                    </Button>
                  )}
                </div>
                {productsCount > 0 && isAbsolute && enableEmptyCartButton && (
                  <div
                    className={clsx(
                      classes.basketActions,
                      classes.basketActionsDelete
                    )}
                  >
                    <Button
                      size="small"
                      color="inherit"
                      className={clsx(
                        'cart-reset',
                        classes.reset,
                        classes.resetSmall
                      )}
                      onClick={emptyCart}
                      startIcon={<HighlightOffIcon />}
                    >
                      <CMSText
                        asText
                        data={basket?.data?.empty_cart_text}
                        defaultText={t('texts:basket:delete_basket')}
                      />
                    </Button>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </ConditionalWrap>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Basket)
