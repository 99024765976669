import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import {
  makeStyles,
  createStyles,
  Theme,
  Input,
  debounce,
} from '@material-ui/core'
import { useTranslation } from 'next-i18next'

import Button from '../Button/Button'
import { Remove } from '@material-ui/icons'
import AddIcon from '@material-ui/icons/Add'
import Loader from '../Loader/Loader'
import { RootState } from '../../redux/reducers/rootReducer'
import { connect } from 'react-redux'
import { isB2B } from '../../utils/constants'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    quantitySelector: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
    },
    btn: {
      padding: 0,
      minWidth: 32,
      width: 32,
      height: 32,
      borderRadius: theme.shape.borderRadius * 3,
    },
    btnLoading: {
      cursor: 'default',
      pointerEvents: 'none',
    },
    alignLeft: {
      justifyContent: 'flex-start',
    },
    isSmall: {
      paddingTop: 0,
      '& .MuiButtonBase-root': {
        minWidth: 22,
        width: 22,
        height: 22,
        '&>span>svg': {
          width: '.75em',
          height: '.75em',
        },
      },
      '& .quantitySelector__total': {
        height: 32,
        width: 35,
        margin: theme.spacing(0, 0.5),
      },
    },
    disableForcedMinus: {
      '&:disabled': {
        border: `1px solid ${theme.palette.primary.main}`,
        color: theme.palette.primary.main,
        background: 'transparent',
      },
    },
    total: {
      position: 'relative',
      zIndex: 2,
      width: 50,
      height: 45,
      margin: theme.spacing(0, 1),
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      border: `1px solid ${theme.extraColors.grey}`,
      borderRadius: theme.shape.borderRadius,
      background: theme.palette.common.white,
      '.isSmall &': {
        width: 52,
      },
      '& input': {
        textAlign: 'center',
        '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
          '-webkit-appearance': 'none',
          margin: 0,
        },
        '&[type=number]': {
          '-moz-appearance': 'textfield',
        },
      },
    },
    disableForcedPlus: {
      '&:disabled': {
        color: theme.palette.common.white,
        background: theme.palette.primary.main,
      },
    },
    overlay: {
      zIndex: 3,
      position: 'absolute',
      top: -2,
      left: -2,
      right: -2,
      bottom: -2,
      background: 'rgba(255,255,255, .8)',
      backdropFilter: 'blur(2px)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      pointerEvents: 'none',
      '&:hover': {
        cursor: 'wait',
        pointerEvents: 'none',
      },
    },
    stockQuantity: {
      fontSize: '0.75rem',
      color: theme.palette.text.secondary,
      width: '100%',
      textAlign: 'center',
    },
  })
)

type TProps = {
  value: number
  handleChange: (number: number) => void
  min?: number
  max?: number
  disable?: boolean
  small?: boolean
  isShop?: boolean
  align?: string
  loading?: boolean
  variantSku: string
}

const mapStateToProps = ({ stock }: RootState, ownProps: TProps) => {
  const stockElement =
    stock?.stockMap?.get(ownProps.variantSku) ??
    stock?.stockBasket?.find(
      (element) => element.lizeeSKU === ownProps.variantSku
    )
  return {
    maxStock: stockElement?.quantity,
  }
}

type TQuantitySelectorProps = ReturnType<typeof mapStateToProps> & TProps

const QuantitySelector = ({
  value,
  handleChange,
  min = 0,
  max,
  small,
  isShop,
  disable,
  align,
  loading,
  maxStock,
}: TQuantitySelectorProps) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [inputValue, setInputValue] = useState<number | undefined>(value)

  const debouncedHandleChange = React.useMemo(
    () => debounce((value: number) => handleChange(value), 400),
    [handleChange]
  )

  const onQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    let _inputValue = value === '' ? undefined : Number(value)
    if (_inputValue && _inputValue > maxStock) {
      _inputValue = maxStock
    }
    setInputValue(_inputValue)
    if (_inputValue !== undefined) {
      debouncedHandleChange(_inputValue)
    }
  }

  useEffect(() => {
    setInputValue(value)
  }, [value])

  // Add cleanup
  useEffect(() => {
    return () => {
      debouncedHandleChange.clear()
    }
  }, [debouncedHandleChange])

  return (
    <div>
      <div
        className={clsx('quantitySelector', classes.quantitySelector, {
          [classes.isSmall]: small,
          [classes.alignLeft]: align === 'left',
        })}
      >
        {loading &&
          (small ? (
            <div className={classes.overlay} />
          ) : (
            <div className={classes.overlay}>
              <Loader />
            </div>
          ))}
        {!loading ? (
          <Button
            className={clsx(
              'quantitySelector__btn',
              'quantitySelector__btn--minus',
              classes.btn,
              isShop && classes.disableForcedMinus
            )}
            data-testid="removeQuantity"
            variant="outlined"
            color="primary"
            type="button"
            disabled={value === min || disable}
            onClick={() => {
              if (value !== min) {
                handleChange(--value)
              }
            }}
          >
            <Remove />
          </Button>
        ) : (
          <span
            className={clsx(
              'MuiButtonBase-root',
              classes.btn,
              classes.btnLoading
            )}
          ></span>
        )}
        <div
          className={clsx('quantitySelector__total', classes.total, {
            [(classes.isSmall, 'quantitySelector__total--small')]: small,
          })}
        >
          {isB2B ? (
            <Input
              value={inputValue}
              onChange={onQuantityChange}
              type="number"
              disableUnderline={true}
              inputProps={{
                min,
                max: maxStock,
              }}
            />
          ) : (
            <span>{value}</span>
          )}
        </div>
        {!loading ? (
          <Button
            className={clsx(
              'quantitySelector__btn',
              'quantitySelector__btn--plus',
              classes.btn,
              isShop && classes.disableForcedPlus
            )}
            data-testid="addQuantity"
            variant="outlined"
            color="primary"
            type="button"
            disabled={!!(max && value === max) || disable}
            onClick={() => {
              if (value !== max) {
                handleChange(++value)
              }
            }}
          >
            <AddIcon />
          </Button>
        ) : (
          <span
            className={clsx(
              'MuiButtonBase-root',
              classes.btn,
              classes.btnLoading
            )}
          ></span>
        )}
      </div>
      {isB2B && maxStock !== undefined && maxStock !== null && (
        <div className={classes.stockQuantity}>
          {t('texts:basket:remainingInStock', {
            count: maxStock - value,
          })}
        </div>
      )}
    </div>
  )
}

export default connect(mapStateToProps)(QuantitySelector)
